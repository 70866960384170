* {
  box-sizing: border-box;
};

.submit-div {
  margin-left: 15px;
}

.submit-intro {
  max-width: 95%;
  float: left;
  border: solid 1px gray;
  background-color: white;
  box-shadow: 3px 5px rgb(112, 112, 112);
  border-radius: 15px;
  margin: 5px;
  padding: 10px;
}

.submit-body {
  max-width: 45%;
  float: left;
  border: solid 1px gray;
  background-color: white;
  box-shadow: 3px 5px rgb(112, 112, 112);
  border-radius: 15px;
  margin: 5px;
  padding: 10px;
}

.sidebar {
  padding-top: 10px;
  padding-left: 15px;
}

iframe {
  max-width: 50%;
}
