/* HEADER */
* {
  font-family: 'Roboto Slab', serif;}
.App-header {
  /* background-color: #282c34; */
  /* background-color: #fcfbfb; */
  background-color: #72defb;
  /* min-height: 100vh; */
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-header h1 {
  /* color: #61dafb; */
  color: #fdfbfb;
  /* font-family: 'Kanit', sans-serif; */
  font-family: 'Exo', sans-serif;
  font-weight: bold;
  font-size: 65px;
}

.App-header nav a {
  opacity: 0.9
}
/* mouse over link */
a:hover {
  color: rgb(190, 113, 11);
  text-decoration: none;
}

header nav {
  /* background-color: #fcfbfb; */
  background-color: #72defb;
  /* background-color: rgba(0,0,0,.2); */
  padding: 5px 0;
  text-align: left;
}

header nav .welcome {
  font-size: 0.85em;
}

header a {
  margin: 10px 20px;
  /* color: white; */
  color: #0f3e4f;
  text-decoration: none;
}

h2 {
  text-align: center;
  margin-top: 40px;
  margin-bottom: 20px;
}

#tag-line {
  margin-top: -25px;
  margin-left: 20px;
  margin-bottom: 15px;
  color: white;
  /* color: black; */
  /* color: #61dafb; */
}

header a:hover {
  /* color: #61dafb; */
  color: rgb(190, 113, 11);
  text-decoration: none;
}

body {
  text-align: left;
  margin: 0 auto;
  /* background: #eee; */
  background: #72defb1a;
}


ul {
  margin: 0;
  padding: 0;
}

ul li {
  /* list-style-type: none; */
  margin: 20px 10px;
}

li button {
  margin-left: 10px;
  position: relative;
  bottom: 2px;
}

label {
  display: block;
  text-align: right;
  margin: 5px auto;
  max-width: 375px;
  color: #0f3e4f;
  /* color: white; */
  margin-left: 10px;
}

label input {
  margin-left: 10px;
  width: 250px;
  padding: 5px;
}

.img_url-container {
  width: 250px;
  position: relative;
  left: 200px;
  border: 1px solid #ddd;
  padding: 20px;
}

.img_url-container img {
  width: 100%;
}

.login-container {
  text-align: center;
  margin-bottom: 60px;
}

.signup-container,
.signin-container,
.logout-container {
  width: 90%;
  max-width: 400px;
  margin: 0 auto;
}

.add-residency-container,
.edit-residency-container
{
  width: 90%;
  max-width: 450px;
  margin: 0px auto;
  margin-bottom: 60px;

}

.add-residency-container label,
.edit-residency-container label
{
  max-width: 450px;
}

.add-residency-container button,
.edit-residency-container button
{
  display: block;
  margin: 20px auto;
}

.signup-container,
.logout-container {
  margin-top: 40px;
  padding-top: 30px;
  border-top: 1px solid black;
}

/* #infoImage {
  flex-wrap: wrap;

} */

.gm-style img {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: auto;
  max-width: 200px;
  max-width: 200px;
}

/* .gm-style {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: auto;
} */


#maps {
  height: 100%;
}

.welcome {
  position: absolute;
  top: 15px;
  right: 15px;
  text-align: right;
}


@media only screen and (max-width: 600px) {
  .welcome {
    max-width: 300px;
  }
  .welcome a  {
    margin: 5px 10px;
  }
}

/* BUTTONS */
button {
  display: inline-block;
  border: 2px dotted #ddd;
  padding: .5rem 1rem;
  margin-left: 10px;
  border-radius: 3px;
  text-decoration: none;
  background: #61dafb;
  color: #ffffff;
  font-family: sans-serif;
  font-size: .8rem;
  cursor: pointer;
  text-align: center;
  transition: background 250ms ease-in-out, 
              transform 150ms ease;
  -webkit-appearance: none;
  -moz-appearance: none;
}

button:hover,
button:focus {
  background: #3aa2bf;
}

button:focus {
  outline: 1px solid #fff;
  outline-offset: -4px;
}

button:active {
  transform: scale(0.99);
}

#top-state {
  color:white;
}

.splash-body {
  margin-top: 20px;
  margin-left: 15px;
}

.splash-intro {
  max-width: 90%;
  padding: 7px;
}

.splash-section {
  max-width: 45%;
  float: left;
  border: solid 1px gray;
  background-color: white;
  box-shadow: 3px 5px rgb(112, 112, 112);
  border-radius: 15px;
  margin: 5px;
  padding: 10px;
}

.search-forms-div {
  height: 60px;
  align-content: center;
  margin-top: 30px;
  margin-left: 150px;
}

.search-input {
  margin-top: 5px;
  margin-left: 15px;
}

form {
  float: left;
}

table {
  border: none;
  width: 80%;
}

thead {
  border: none;
}

table tr:nth-child(even){background-color: #ddf5fc;}

table tr:hover {background-color: #aeccd4;}

table th {
  padding: 12px;
  text-align: left;
  font-size: 22px;
  background-color: #72defb;
  color: white;
}

td {
  padding: 10px;
  font-size: 18px;
}

.main {
  padding-left: 15px;
  padding-top: 15px;
}

.fund-detail {
  margin: 1rem;
  padding: 1rem;
  background: white;
  border: solid 3px #55a5bb;
  border-radius: 15px;
  width: auto;
}