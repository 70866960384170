/* * {
  color: white;
} */
.search {
  
  display: flex;
  justify-content: right;
  margin: auto;
  /* background-color: #282c34; */
  /* background-color: #fcfbfb; */
  background-color: #72defb;
}


.search button {
  box-shadow: 3px 2px 0px 0px rgb(118, 222, 250);
  background: linear-gradient(to bottom, white 5%, white 100%);
  background-color: rgb(255, 255, 255);
  border: 1px dotted;
  cursor: pointer;
  color: #0083ac;
  /* font-family: Arial; */
  font-family: 'Bellota Text', cursive;
  font-size: 17px;
  padding: 7px 25px;
  text-decoration: none;
  margin-bottom: 12px;
  margin-left: 5px;
}

.search button:hover {
  color: white
}

.search input {
    padding: 0 5px;
    height: 40px;
    width: 225px;
    border: 1px solid #cdcdcd;
    border-color: rgba(0, 0, 0, .15);
    background-color: whitesmoke;
    font-size: 16px;
    margin: 5px 5px 12px 0px;
}


.dropdown {
  box-shadow: 3px 2px 0px 0px rgb(118, 222, 250);
  background: linear-gradient(to bottom, white 5%, white 100%);
  background-color: rgb(255, 255, 255);
  /* border-radius: 18px; */
  border: 1px dotted;
  cursor: pointer;
  color: #0083ac;
  /* font-family: Arial; */
  font-family: 'Bellota Text', cursive;
  font-size: 17px;
  /* padding: 7px 25px; */
  text-decoration: none;
  text-shadow: 0px 1px 0px;
  height: 41px;
  width: 60px;
  /* margin-bottom: 12px;
  margin-left: 5px; */
}

.search form {
  margin-left: 20px;
}

.search form select {
  margin-left: 5px;
}

/* .dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  padding: 12px 16px;
  z-index: 1;
} */

/* .dropdown:hover {
  display: block;
  background-color: red;
} */

/* select {
  height: 10px;
}
   */